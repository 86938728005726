import React, { useState, useRef } from "react"
import { Radar } from "react-chartjs-2"
// import pdfConverter from "jspdf"
// import { isSSR } from "../../../components/Script/script"

import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import DropDownList from "../../../componentForPages/DropDown/DropDown"
import {
  Title,
  Button,
  Input,
  SpanVerticalAlign,
  Card,
  CardContent,
  CardFooter,
} from "../../../StyleComponents/styles"
import {
  FlexDiv,
  ColorImput,
  CheckBox,
} from "../../../StyleComponents/pagesStyle"
// const pdfConverter = (() => {
//   if (!isSSR) {
//     return require("jspdf")
//   }
// })()
const dropDownList = [
  { name: "Line Chart Maker", link: "online-chart-maker" },
  { name: "Bar Chart Maker", link: "bar-chart-maker" },
  { name: "Horizontal Bar Chart Maker", link: "horizontal-bar-chart-maker" },
  { name: "Radar Chart Maker", link: "radar-chart-maker" },
  { name: "Pie Chart Maker", link: "pie-chart-maker" },
  { name: "Doughnut Chart Maker", link: "doughnut-chart-maker" },
  { name: "Polar Chart Maker", link: "polar-chart-maker" },
]
const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  {
    name: "Radar Chart Maker",
    link: "/radar-chart-maker/",
  },
]

function PasswordGenerator(props) {
  let [labels, setlabels] = useState(["Jan", "Feb", "Mar", "Apr", "May", "Jun"])
  let [labelsValue, setlabelsValue] = useState("Jan, Feb, Mar, Apr, May, Jun")
  const chartRef = useRef(null)
  let [datasets, setdatasets] = useState([
    {
      label: "First dataset",
      data: [33, 53, 85, 41, 44, 65],
      fill: true,
      backgroundColor: `#4BC0C050`,
      borderColor: "#4BC0C0",
    },
    {
      label: "Second dataset",
      data: [33, 25, 35, 51, 54, 76],
      fill: false,
      borderColor: "#742774",
      backgroundColor: "#74277450",
    },
  ])

  let data = {
    labels: labels,
    datasets: datasets,
  }

  const updateFieldChanged = index => e => {
    let newArr = [...datasets] // copying the old datasets array
    newArr[index].label = e.target.value // replace e.target.value with whatever you want to change it to
    setdatasets(newArr) // ??
  }
  const updateDataChanged = index => e => {
    let newArr = [...datasets] // copying the old datasets array
    newArr[index].data = e.target.value.split(",") // replace e.target.value with whatever you want to change it to
    setdatasets(newArr) // ??
  }

  const changeFIllColor = index => e => {
    let newArr = [...datasets]
    e.target.checked === true
      ? (newArr[index].fill = true)
      : (newArr[index].fill = false)
    setdatasets(newArr) // ??
  }

  const changeBorderColor = index => e => {
    let newArr = [...datasets] // copying the old datasets array
    newArr[index].borderColor = e.target.value // replace e.target.value with whatever you want to change it to
    setdatasets(newArr) // ??
  }

  const changeBackgroundColor = index => e => {
    let newArr = [...datasets] // copying the old datasets array
    newArr[index].backgroundColor = e.target.value + "50" // replace e.target.value with whatever you want to change it to
    setdatasets(newArr) // ??
  }

  function changingLabels(e) {
    setlabelsValue(e.target.value)
    setlabels(e.target.value.split(","))
  }

  function addItem() {
    setdatasets([
      ...datasets,
      {
        label: "new dataset" + Math.floor(Math.random() * 10 + 1),
        data: [
          Math.floor(Math.random() * 10 + 1),
          Math.floor(Math.random() * 100 + 1),
          Math.floor(Math.random() * 100 + 1),
          Math.floor(Math.random() * 100 + 1),
          Math.floor(Math.random() * 100 + 1),
          Math.floor(Math.random() * 100 + 1),
        ],
        fill: false,
        borderColor: "#742774",
        backgroundColor: "#74277450",
      },
    ])
  }
  const removeByIndex = index => e => {
    let newArr = [...datasets]
    newArr.splice(index, 1)
    setdatasets(newArr) // ??
  }
  function download_image() {
    if (typeof window !== "undefined") {
      const base64Image = chartRef.current.chartInstance.toBase64Image()
      document.getElementById("download").href = base64Image
    }
  }

  function demoFromHTML() {
    import("jspdf").then(jsPDF => {
      const imgData = chartRef.current.chartInstance.toBase64Image()
      const pdf = new jsPDF.default("l", "pt")
      pdf.addImage(imgData, "JPEG", 15, 110, 800, 450)
      pdf.save("chart.pdf")
    })
  }
  return (
    <Layout location={props.location}>
      <SEO
        title="Radar Chart Maker"
        description="An online chart maker tool to create radar charts or any type of chart. Make your own chart and download it pdf or png image."
        keywords={[
          "make chart, make chart, download chart, generate chart, Radar chart, fast Radar chart maker, Radar chart maker.",
        ]}
      />

      <NavBar listPages={navRoadArray} />
      <section className="container">
        <Title>Radar Chart Maker</Title>
        <br />
        <DropDownList title="Chart Type" dopDownList={dropDownList} />
        <Radar data={data} ref={chartRef} />

        <br />

        <FlexDiv displayDiv="flex" className="mb-3">
          <label htmlFor="labels">
            <SpanVerticalAlign>Labels: </SpanVerticalAlign>
          </label>
          <Input
            type="text"
            name="labels"
            value={labelsValue}
            onChange={changingLabels}
          />
        </FlexDiv>

        {datasets.map((e, index) => {
          return (
            <Card key={"data" + index} maxWidth="600px" className="mb-4">
              <CardContent>
                <div className="mb-2">
                  <label htmlFor={"dataset" + index}>
                    dataset {index + 1}:{" "}
                  </label>
                  <Input
                    type="text"
                    name={"dataset" + index}
                    value={e.label}
                    onChange={updateFieldChanged(index)}
                  ></Input>
                </div>
                <div className="mb-2">
                  <label htmlFor={"data" + index}>data: </label>

                  <Input
                    name={"data" + index}
                    type="text"
                    value={e.data}
                    onChange={updateDataChanged(index)}
                  />
                </div>
                <div>
                  <SpanVerticalAlign verticalAlign="super">
                    Fill Color:{" "}
                  </SpanVerticalAlign>
                  <CheckBox
                    type="checkbox"
                    aria-label={"fillColor" + index}
                    onChange={changeFIllColor(index)}
                    checked={e.fill === true ? "checked" : ""}
                  />
                </div>
                <div>
                  <SpanVerticalAlign verticalAlign="super">
                    Border Color:{" "}
                  </SpanVerticalAlign>
                  <ColorImput
                    type="color"
                    aria-label={"borderColor" + index}
                    value={e.borderColor}
                    onChange={changeBorderColor(index)}
                  />
                </div>
                <div>
                  <SpanVerticalAlign verticalAlign="super">
                    Background Color:{" "}
                  </SpanVerticalAlign>
                  <ColorImput
                    type="color"
                    aria-label={"colorColor" + index}
                    value={e.backgroundColor.substring(
                      0,
                      e.backgroundColor.length - 2
                    )}
                    onChange={changeBackgroundColor(index)}
                  />
                </div>
              </CardContent>
              <CardFooter>
                <Button
                  margin="0"
                  borderColor="#ea4336"
                  color="white"
                  backgroundColor="#ea4336"
                  hoverBorderColor="#ea4336"
                  hoverBackgroundColor="white"
                  hoverColor="#ea4336"
                  onClick={removeByIndex(index)}
                >
                  remove
                </Button>
              </CardFooter>
            </Card>
          )
        })}

        <Button
          borderColor="#34a853"
          color="white"
          backgroundColor="#34a853"
          hoverBorderColor="#34a853"
          hoverBackgroundColor="white"
          hoverColor="#34a853"
          onClick={addItem}
        >
          Add item
        </Button>

        <br />
        <div className="p-3">
          <a
            href="#"
            id="download"
            onClick={download_image}
            download="chart-image.png"
          >
            Download Image
          </a>
          <Button
            borderColor="#1da1f2"
            color="#1da1f2"
            hoverColor="white"
            hoverBorderColor="#1da1f2"
            hoverBackgroundColor="#1da1f2"
            onClick={demoFromHTML}
          >
            download pdf
          </Button>
        </div>
        <br />
        <p>
          An online Radar chart maker with these easy steps, and download Radar
          chart image or pdf.
        </p>
        <br />
      </section>
    </Layout>
  )
}

export default PasswordGenerator
